import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "cg-content-container",
  role: "dialog",
  "aria-labelledby": "cookie-notification-title"
}
const _hoisted_3 = {
  id: "cookie-notification-title",
  class: "visually-hidden"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = {
  key: 0,
  class: "modal-background"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$data.viewedCookieDisclaimer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, { name: "cookie-gate" }, {
          default: _withCtx(() => [
            (!$data.closed)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: "hw-cookie-alert",
                  class: _normalizeClass([$props.type]),
                  role: "complementary"
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h1", _hoisted_3, _toDisplayString($props.titleCopy), 1),
                    _createElementVNode("p", { innerHTML: $props.bodyCopy }, null, 8, _hoisted_4),
                    ($props.buttonType === 'button')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          title: $props.buttonCopy,
                          class: "cookie-gate-close-button",
                          "data-e2e": "cookie-gate-close",
                          onClick: _cache[0] || (_cache[0] = (...args) => ($options.close && $options.close(...args))),
                          onKeyup: _cache[1] || (_cache[1] = _withKeys((...args) => ($options.close && $options.close(...args)), ["enter"]))
                        }, _toDisplayString($props.buttonCopy), 41, _hoisted_5))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          "data-e2e": "cookie-gate-close",
                          class: "cookie-gate-close-icon",
                          onClick: _cache[2] || (_cache[2] = (...args) => ($options.close && $options.close(...args))),
                          onKeyup: _cache[3] || (_cache[3] = _withKeys((...args) => ($options.close && $options.close(...args)), ["enter"]))
                        }, [
                          _createElementVNode("i", {
                            class: "fas fa-times",
                            role: "button",
                            "aria-label": $props.buttonCopy,
                            tabindex: "0"
                          }, null, 8, _hoisted_6)
                        ], 32))
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "cookie-gate-overlay" }, {
          default: _withCtx(() => [
            (!$data.closed && $props.type === 'popup')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}